import React, {
  ReactElement,
  useState,
  Ref,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { v4 } from "uuid";
import { InputHTMLAttributes } from "react";
import {
  Controller,
  Control,
  FieldValues,
  FieldPath,
  RegisterOptions,
} from "react-hook-form";
import { ZXCVBNResult } from "zxcvbn";
import { t } from "@lingui/macro";

import { FormField, FormFieldProps } from "_/components/form-field";
import { Overlay } from "_/components/overlay";

import { palette } from "_/style";

import * as S from "./styled";

// Constrain the available types for the input.
type InputType =
  | "text"
  | "password"
  | "number"
  | "tel"
  | "email"
  | "url"
  | "date";

type InputProps = FormFieldProps &
  InputHTMLAttributes<HTMLInputElement> & {
    /** Input type used for the underlying input element. */
    type?: InputType;

    /** True if border should be drawn around element. */
    border?: boolean;

    /** Icon to be displayed to the left of the input text.*/
    iconLeft?: ReactElement;

    /** Icon to be displayed to the right of the input text.*/
    iconRight?: ReactElement;

    /** For InputType 'password', when set to true, uses right icon as password visibility toggle. */
    togglePasswordVisibility?: boolean;

    /** Sets state as invalid when TextField is being used as a controlled component.*/
    invalid?: boolean;
  };

const _TextField = (
  {
    type = "text",
    border = true,
    iconLeft,
    iconRight,
    invalid,
    style,
    className,
    togglePasswordVisibility = true,
    label,
    hint,
    help,
    placeholder = type === "password" ? t`common.password` : undefined,
    ...restProps
  }: InputProps,
  ref: Ref<HTMLInputElement | null>
): ReactElement => {
  const [touched, setTouched] = useState(false);
  const [focused, setFocused] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  // get reference to input element to check invalid state
  // that can be passed up to the wrapper div
  const inputRef = useRef<HTMLInputElement>(null);

  // associate the forwarded ref with the internal reference
  // to the input element
  useImperativeHandle(ref, () => inputRef.current);

  // When generating a random ID if none is provided, cache the value to
  // prevent unnecessary re-renders.
  const [id, _] = useState(restProps.id || v4());

  const isInvalid = invalid || (!inputRef.current?.checkValidity() && touched);

  const passwordVisibilityToggle = (
    <S.ClickableIcon
      tabIndex={-1}
      variant={showPassword ? "VisibilityOff" : "Visibility"}
      onClick={() => setShowPassword(!showPassword)}
    />
  );

  // Type to use for the input element - use the provided type except in the case of a password,
  // in which case set the input type either to 'text' or 'password' based on the value of 'showPassword'.
  const computedType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  // Compute how to set the right icon - if the provided input type is 'password' and the togglePasswordVisibility prop is set to true,
  // use passwordVisibilityToggle as right icon, even if another icon is provided for iconRight.
  let computedIconRight = iconRight;
  if (type === "password" && togglePasswordVisibility) {
    computedIconRight = passwordVisibilityToggle;
  }

  // The `className` prop must be provided to the wrapper to allow for
  // extending a component with styled-components.
  const textInput = (
    <S.Wrapper
      $invalid={isInvalid}
      $border={border}
      $disabled={restProps.disabled}
      $focused={focused}
      className={className}
      style={style}
    >
      {iconLeft}
      <S.Input
        {...restProps}
        ref={inputRef}
        id={id}
        disabled={restProps.disabled}
        placeholder={placeholder}
        type={computedType}
        onFocus={(e) => {
          setFocused(true);
          restProps.onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          setTouched(true);
          restProps.onBlur?.(e);
        }}
      />
      {computedIconRight}
    </S.Wrapper>
  );

  return (
    <FormField hint={hint} invalid={isInvalid} label={label} help={help}>
      {textInput}
    </FormField>
  );
};

export const TextField = forwardRef(_TextField);

interface FormTextFieldProps<T extends FieldValues, K extends FieldPath<T>>
  extends Omit<InputProps, "invalid" | "name" | "hint" | "type"> {
  rules?: Omit<
    RegisterOptions<T, K>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  name: K;
  control: Control<T, K>;
  type?: InputHTMLAttributes<HTMLInputElement>["type"] | "new-password";
}

type PasswordStrengthIndicatorProps = {
  /** Password strength score number between 0 and 4. */
  score: number;
};

const GREY = palette.anchorGrey;

/**
 * Overlay content for password validation textfield.
 * Displays password strength score and suggestions to make the password stronger.
 */
const PasswordStrengthIndicator = ({
  score = -1,
}: PasswordStrengthIndicatorProps): ReactElement => {
  // Options for label text and block / label color.
  const outcomes = [
    [t`components.password-strength-indicator.very-weak`, palette.tomatoRed],
    [t`components.password-strength-indicator.weak`, palette.pineappleYellow],
    [t`components.password-strength-indicator.strong`, palette.leafGreen],
    [t`components.password-strength-indicator.very-string`, palette.kellyGreen],
  ];

  // map scores to the block indices
  // treat scores of 0 or 1 the same (very weak)
  const adjustedScore = score <= 0 ? score : score - 1;

  const [label, color] =
    adjustedScore >= 0 ? outcomes[adjustedScore] : ["", GREY];

  function renderBlock(index: number) {
    const color = adjustedScore >= index ? outcomes[adjustedScore][1] : GREY;

    return <S.Block $color={color} />;
  }

  return (
    <S.ScoreWrapper>
      {renderBlock(0)}
      {renderBlock(1)}
      {renderBlock(2)}
      {renderBlock(3)}
      <S.ScoreLabel $color={color}>{label}</S.ScoreLabel>
    </S.ScoreWrapper>
  );
};

type PasswordValidation = { password: string; valid: boolean };

type PasswordFieldProps = Omit<InputProps, "value" | "onChange"> & {
  value: PasswordValidation;
  onChange: (validation: PasswordValidation) => void;
};

/**
 * Modified password TextField component with password validation overlay.
 */
const _PasswordField = (
  { value, onChange, ...rest }: PasswordFieldProps,
  ref: Ref<HTMLInputElement | null>
) => {
  const [validationResult, setValidationResult] = useState<ZXCVBNResult>();

  const [focused, setFocused] = useState(false);

  // Dynamically import "zxcvbn" to avoid loading the ~400kb module as part of the initial bundle.
  // See details here: https://github.com/dropbox/zxcvbn?tab=readme-ov-file#script-load-latency
  // Initiate the load when the component is first rendered in order to avoid latency when it is
  // called in the `validate()` function.
  const zxcvbnPromise = import("zxcvbn");

  function validate(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e.target.value;

    zxcvbnPromise.then((zxcvbn) => {
      const result = zxcvbn.default(password);
      const validation = { password, valid: result.score >= 3 };
      setValidationResult(result);
      onChange(validation);
    });
  }

  const textField = (
    <TextField
      {...rest}
      ref={ref}
      value={value.password}
      type="password"
      onChange={validate}
      autoComplete="new-password"
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
    />
  );

  const validationDisplay = validationResult ? (
    <S.ValidationDisplayWrapper>
      <PasswordStrengthIndicator
        score={
          validationResult.sequence.length > 0 ? validationResult.score : -1
        }
      />
      {validationResult.feedback.warning && (
        <S.WarningContainer>
          <S.WarningIcon variant={"Warning"} />
          {validationResult.feedback.warning}
        </S.WarningContainer>
      )}
      {!!validationResult.feedback.suggestions.length && (
        <S.SuggestionsContainer>
          <p>{t`components.password-strength-indicator.suggestions`}:</p>
          <S.SuggestionList>
            {validationResult.feedback.suggestions.map(
              (suggestion: string, i: number) => (
                <li key={i}>{suggestion}</li>
              )
            )}
          </S.SuggestionList>
        </S.SuggestionsContainer>
      )}
    </S.ValidationDisplayWrapper>
  ) : null;

  return (
    <Overlay
      target={textField}
      content={validationDisplay}
      placement={"leftStart"}
      delay={0}
      wait={0}
      keepOpen={focused}
      fullWidth
    />
  );
};

const PasswordField = forwardRef(_PasswordField);

/**
 * DRY text field component for use with react-hook-form.
 */
export function FormTextField<T extends FieldValues, K extends FieldPath<T>>(
  props: FormTextFieldProps<T, K>
) {
  const [valid, setValid] = useState(false);

  const { name, control, rules, type, ...rest } = props;

  // Use password validation over provided validation callback if
  // the type of form field is specifically `"new-password"`
  const combinedRules = {
    ...rules,
    validate:
      type === "new-password"
        ? () => valid || t`components.password-form-field.validation-failed`
        : rules?.validate,
  };

  return (
    <Controller
      control={control}
      rules={combinedRules}
      name={name}
      render={({ field, fieldState }) =>
        type === "new-password" ? (
          <PasswordField
            {...rest}
            {...field}
            onChange={(validation: PasswordValidation) => {
              field.onChange(validation);
              setValid(validation.valid);
            }}
            invalid={fieldState.invalid}
            hint={fieldState.error?.message}
          />
        ) : (
          <TextField
            {...rest}
            {...field}
            type={type}
            invalid={fieldState.invalid}
            hint={fieldState.error?.message}
          />
        )
      }
    />
  );
}

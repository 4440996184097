import { useMutation, useQuery, useQueryClient } from "react-query";

import { api, ApiResponse } from "_/utils";
import { Uuid } from "_/types";
import { AxiosError } from "axios";

const SCOPE = "materials";

const KEYS = {
  materials: () => [SCOPE],
  material: (materialId: Uuid) => [SCOPE, materialId],
  manufacturers: () => [SCOPE, "manufacturers"],
  manufacturer: (manufacturerId: Uuid) => [
    SCOPE,
    "manufacturers",
    manufacturerId,
  ],
};

export type Material = {
  id: Uuid;
  domainId?: Uuid;
  manufacturer: Manufacturer;
  name: string;
  color: string;
  pigmented: boolean;
  dataSheetId?: Uuid;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
  capabilities: {
    optimization: boolean;
    simulation: boolean;
  };
};

export type Manufacturer = {
  id: Uuid;
  name: string;
  website: string;
  createdAt: string;
  updatedAt: string;
};

export type NewMaterial = Pick<
  Material,
  "domainId" | "name" | "color" | "pigmented" | "capabilities"
> & { manufacturerId: Uuid };

export type MaterialUpdate = Partial<
  Pick<
    Material,
    "name" | "color" | "pigmented" | "capabilities" | "archived"
  > & { manufacturerId: Uuid }
>;

export type NewManufacturer = Pick<Manufacturer, "name" | "website">;

export type ManufacturerUpdate = Partial<NewManufacturer>;

export function useMaterials() {
  async function getMaterials(): Promise<Material[]> {
    const response = await api.get<ApiResponse<Material[]>>("/materials");
    return response.data.data;
  }

  return useQuery({
    placeholderData: [],
    queryKey: KEYS.materials(),
    queryFn: getMaterials,
  });
}

export function useCreateMaterial() {
  async function createMaterial(params: {
    newMaterial: NewMaterial;
    onError?: (errorCode?: number) => void;
  }): Promise<Material> {
    const response = await api.post<ApiResponse<Material>>(
      "/materials",
      params.newMaterial
    );
    return response.data.data;
  }

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMaterial,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.materials());
    },
    onError: (e: AxiosError, { onError }) => {
      onError?.(e.response?.status);
    },
  });
}

export function useUpdateMaterial() {
  async function updateMaterial(params: {
    id: Uuid;
    update: MaterialUpdate;
    onError?: (errorCode?: number) => void;
  }): Promise<Material> {
    const response = await api.patch<ApiResponse<Material>>(
      `/materials/${params.id}`,
      params.update
    );
    return response.data.data;
  }

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMaterial,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.materials());
    },
    onError: (e: AxiosError, { onError }) => {
      onError?.(e.response?.status);
    },
  });
}

export function useManufacturers() {
  async function getManufacturers(): Promise<Manufacturer[]> {
    const response =
      await api.get<ApiResponse<Manufacturer[]>>("/manufacturers");
    return response.data.data;
  }

  return useQuery({
    placeholderData: [],
    queryKey: KEYS.manufacturers(),
    queryFn: getManufacturers,
  });
}

export function useCreateManufacturer() {
  async function createManufacturer(params: {
    newManufacturer: NewManufacturer;
    onError?: (errorCode?: number) => void;
  }): Promise<Manufacturer> {
    const response = await api.post<ApiResponse<Manufacturer>>(
      "/manufacturers",
      params.newManufacturer
    );
    return response.data.data;
  }

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createManufacturer,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.manufacturers());
    },
    onError: (e: AxiosError, { onError }) => {
      onError?.(e.response?.status);
    },
  });
}

export function useUpdateManufacturer() {
  async function updateManufacturer(params: {
    id: Uuid;
    update: ManufacturerUpdate;
    onError?: (errorCode?: number) => void;
  }): Promise<Manufacturer> {
    const response = await api.patch<ApiResponse<Manufacturer>>(
      `/manufacturers/${params.id}`,
      params.update
    );
    return response.data.data;
  }

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateManufacturer,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.materials());
    },
    onError: (e: AxiosError, { onError }) => {
      onError?.(e.response?.status);
    },
  });
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { groupBy, has, indexBy, prop } from "ramda";

import { ChipKind } from "_/components/chip";
import { Project } from "_/data/projects";
import { Uuid } from "_/types";

import { UNKNOWN_VALUE_STRING, UUID_REGEX } from "./consts";

/**
 * Require all Axios methods to be type-safe.
 */
interface AxiosInstanceRequireType
  extends Omit<
    AxiosInstance,
    | "get"
    | "delete"
    | "head"
    | "options"
    | "post"
    | "put"
    | "patch"
    | "postForm"
    | "putForm"
    | "patchForm"
  > {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  get<T, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  delete<T, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  head<T, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  options<T, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  post<T, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  put<T, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  patch<T, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  postForm<T, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  putForm<T, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  patchForm<T, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Axios client to be used for all API requests in the the app.
 */
export const api = axios.create({
  baseURL: "/api/v0",
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
}) as AxiosInstanceRequireType;

export type ApiResponse<T> = { data: T };

interface TemplateArg {
  [k: string]: number | number[] | string | string[] | TemplateArg;
}

type TemplateFunc = (map: TemplateArg) => string;

/**
 * Generate a template function from the provided string.
 *
 * Template strings can be defined in the same way you would define standard
 * template literals, except using double quotations, rather than backticks.
 *
 * Example:
 *
 * ```ts
 * const helloTemplate = generateTemplate("Hello, ${name}!");
 * const hello = helloTemplate({ name: "Fry" });
 * console.log(hello);
 *
 * // "Hello, Fry!"
 * ```
 */
export function generateTemplate(template: string): TemplateFunc {
  // In the template string, replace "${expressions}" etc. with
  // "${map.expressions}". Afterwards, replace anything that's not
  // "${map.expressions}" with a blank string.
  const sanitized = template.replace(/\${(\s*[^;\s${]+?\s*)\}/g, (_, match) => {
    return `\${map.${match.trim()}}`;
  });

  const errors = sanitized.match(/(\$\{(?!map\.)[^}]+\})/g);

  if (errors && errors.length !== 0) {
    const errorDetail = errors.map((e) => `"${e}"`).join("\n");
    throw new Error(`Errors found in template string:\n${errorDetail}`);
  }

  const fn = Function("map", `return \`${sanitized}\``) as TemplateFunc;

  return fn;
}

/**
 * Poll a function continuously until either success, failure, or a timeout.
 *
 * The provided function must return a promise yielding the desired result,
 * while the predicate function must return a promise wrapped boolean value,
 * or throw an error if cancellation is desired.
 *
 * See tests for example usage.
 */
export async function poll<T>(
  fn: () => Promise<T>,
  predicate: (arg: T) => Promise<boolean>,
  wait: number,
  timeout: number
): Promise<T> {
  let result = await fn();
  let complete = await predicate(result);

  let elapsed = 0;

  const sleep = async () => {
    return new Promise((resolve) => {
      setTimeout(resolve, wait);
    });
  };

  while (!complete && elapsed < timeout) {
    await sleep();
    elapsed += wait;

    result = await fn();

    try {
      complete = await predicate(result);
    } catch (_err) {
      throw new Error("Cancelling polling");
    }
  }

  if (elapsed >= timeout) {
    throw new Error(`Polling timed out after ${timeout}ms`);
  }

  return result;
}

/**
 * Finds the index of the most significant bit of an integer value.
 *
 * Note that javascript uses the IEEE-754 floating point representation
 * for all numbers, but performs bitwise operations on 32 bits, converting
 * values to signed 32 bit integers. This may lead to unexpected results if
 * used with large floating point or integer numbers.
 */
export function msb(n: number): number {
  let x = n | 0;

  if (n < 0) {
    x = ~x;
  }

  if (!Number.isInteger(n) || x !== n) {
    console.warn(
      "Bitwise operations are within the 32 bit integer domain.",
      `Unexpected msb calculation for ${n} may occur when using negative,`,
      "fractional, or large numbers."
    );
  }

  let bitIndex = -1;
  while (x > 0) {
    x = x >> 1;
    bitIndex++;
  }

  return bitIndex;
}

/**
 * Generic no-op method that can fit in any type slot.
 */
export function noop(..._: unknown[]) {
  /** Noop */
}

/**
 * Maps project status to correct chip display color variant and label.
 */
export function matchProjectChip(
  status: Project["status"]
): [ChipKind, string] {
  switch (status) {
    case "active":
      return ["warning", t`common.projects.in-progress`];
    case "failed":
      return ["error", t`common.projects.failed`];
    case "complete":
      return ["success", t`common.projects.successful`];
    case "archived":
      return ["muted", t`common.projects.archived`];
    case "hold":
    default:
      return ["muted", t`common.projects.on-hold`];
  }
}

// Timer hook used to debounce mouse/scroll input
export function useTimer(timeout: number): [boolean, () => void] {
  // Time is set to "finished" until the first call to resetTimer
  const [timerFinished, setTimerFinished] = useState(true);
  const [timer, setTimer] = useState<number | null>(null);

  // starts / restarts the timer with the given timeout amount
  const resetTimer = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    setTimerFinished(false);
    setTimer(
      window.setTimeout(() => {
        setTimerFinished(true);
      }, timeout)
    );
  };

  // clear the timer on unmount
  useEffect(() => {
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return [timerFinished, resetTimer];
}

/**
 * Transform an array of records into an object whose keys are the `id` property
 * of each array element and whose values are the array elements themselves.
 */
export function indexById<T extends { id: Uuid }>(arr: T[]): Record<Uuid, T> {
  return indexBy(prop("id"), arr) as Record<Uuid, T>;
}

/**
 * Transform an array of records into an object grouped by values of a
 * particular property, typically a foreign key like `machineId` or `projectId`.
 * The keys of the returned object are the values of the desired key property,
 * and the values are arrays of records that match that IDs's value.
 */
export function groupById<
  K extends keyof T & string,
  T extends { [key in K]: Uuid },
>(arr: T[], propName: K): Record<Uuid, T[]> {
  return groupBy(prop(propName), arr) as Record<Uuid, T[]>;
}

interface FormatTemperatureParams {
  /**
   * Temperature to format. If `0`, `null` or `undefined` then will return a
   * non-breaking "--".
   * */
  value: number | null | undefined;
  /**
   * `"current"` formats one decimal place, with trailing zeroes.
   * `"target"` formats at most one decimal place, without trailing zeroes.
   */
  kind: "current" | "target";
}

/** Format a Celsius temperature with one decimal digit and ℃ suffix*/
export function formatTemperature({ value, kind }: FormatTemperatureParams) {
  if (!value) return UNKNOWN_VALUE_STRING;

  const rounded = Math.round((value + Number.EPSILON) * 10) / 10;
  return kind === "current" ? `${rounded.toFixed(1)}℃` : `${rounded}℃`;
}

/**
 * TS type guard to check if a particular key is present in an object. The
 * return type uses `keyof` to ensure that it can be used to index the object,
 * which would not ordinarily be possible with a string return type.
 */
export function isKeyOf<T extends Record<string, unknown>>(
  obj: T,
  key: string
): key is keyof T & string {
  return has(key, obj);
}

/** Returns true if the argument is a valid UUID string, false otherwise */
export function isValidUuid(id: string | undefined | null): id is Uuid {
  return typeof id === "string" && new RegExp(UUID_REGEX).test(id);
}

type TypedArray =
  | Float32Array
  | Uint8Array
  | Int32Array
  | Uint16Array
  | Int16Array
  | Uint32Array
  | Float64Array
  | Int8Array;

// A utility class for accumulating data in `TypedArray` chunks.
export class ChunkedAccumulator<T extends TypedArray> {
  private chunks: T[] = [];
  private currentChunk: T | null = null;
  private currentChunkIndex = 0;

  constructor(
    /**
     * The length of the `TypedArray` instances used to store each chunk.
     */
    private chunkSize: number,
    /**
     * The type of the `TypedArray` to use for each chunk.
     */
    private ArrayType: { new (length: number): T }
  ) {}

  push(value: number) {
    // Create a new chunk if the current one is `null` or full.
    if (
      this.currentChunk === null ||
      this.currentChunkIndex === this.chunkSize
    ) {
      this.currentChunk = new this.ArrayType(this.chunkSize);
      this.chunks.push(this.currentChunk);
      this.currentChunkIndex = 0;
    }

    // Add the value to the current chunk.
    this.currentChunk[this.currentChunkIndex] = value;
    this.currentChunkIndex++;
  }

  // Get the total length of the data accumulated so far.
  get length() {
    const filledChunksLength = (this.chunks.length - 1) * this.chunkSize;
    const lastChunkLength = this.currentChunkIndex;
    return filledChunksLength + lastChunkLength;
  }

  getChunks() {
    return this.chunks;
  }

  // Get the data accumulated so far as a single `TypedArray` instance.
  // If `minLength` is provided, the returned array will be zero-padded to
  // ensure that it has at least that length.
  getData(minLength?: number) {
    const data = new this.ArrayType(minLength ?? this.length);
    let index = 0;

    for (const chunk of this.chunks) {
      // Copy the chunk into the data array, making sure not to exceed the
      // total length of the data array.
      if (index + chunk.length > this.length) {
        const subArray = chunk.subarray(0, this.length - index);
        data.set(subArray, index);
      } else {
        data.set(chunk, index);
        index += chunk.length;
      }
    }

    return data;
  }
}

import React, { ReactNode, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row } from "@tanstack/react-table";
import { t } from "@lingui/macro";

import { Button } from "_/components/button";
import { DateTime } from "_/components/date-time";
import { SubmitCancelButtons, useModal } from "_/components/modal";
import { ColumnDef, Table } from "_/components/table";
import { FormTextField } from "_/components/text-field";
import { FormSelect, Option } from "_/components/select";
import { UuidTooltip } from "_/components/uuid-tooltip";
import { FormField } from "_/components/form-field";
import { MenuItem, OverlayMenu } from "_/components/overlay-menu";
import { Icon } from "_/components/icon";
import { RadioButton, RadioGroup } from "_/components/radio";
import { useToasts } from "_/components/toasts";
import { PermissionTest, testPermissions } from "_/components/permission-test";

import {
  useMaterials,
  Material,
  Manufacturer,
  useManufacturers,
  useCreateMaterial,
  useUpdateMaterial,
  NewMaterial,
  MaterialUpdate,
  NewManufacturer,
  ManufacturerUpdate,
  useCreateManufacturer,
  useUpdateManufacturer,
} from "_/data/materials";
import { ROOT_DOMAIN, useCurrentUserPermissions } from "_/data/rbac";
import { Uuid } from "_/types";

import * as S from "./styled";

// Type for the default / current value of `MaterialEditForm`
type MaterialValue = {
  name: string;
  color: string;
  pigmented: boolean;
  manufacturerId?: Uuid;
  capabilities?: Material["capabilities"];
};

/**
 * Table for displaying all materials manufacturers.
 */
const ManufacturersTable = ({
  actions,
}: {
  actions?: (machine: Manufacturer) => MenuItem[];
}) => {
  const { data: manufacturers } = useManufacturers();
  if (!manufacturers?.length) return null;

  const columns: ColumnDef<Manufacturer>[] = [
    {
      id: "name",
      header: t`common.name`,
      accessorKey: "name",
      cell: ({ row: { original: rowData } }) => (
        // The ID here is to enable linking from the materials table.
        <div id={rowData.id}>{rowData.name}</div>
      ),
    },
    {
      id: "website",
      header: t`common.website`,
      accessorKey: "website",
      cell: ({ row: { original: rowData } }) => (
        <a href={rowData.website} rel="noreferrer" target="_blank">
          {rowData.website}
        </a>
      ),
    },
    {
      id: "id",
      header: t`common.id`,
      accessorKey: "id",
      cell: ({ row: { original: rowData } }) => (
        <UuidTooltip uuid={rowData.id} />
      ),
    },
  ];

  if (actions) {
    columns.push({
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <OverlayMenu
          target={
            <Button kind="secondary" size="small" icon>
              <Icon variant="MoreVert" />
            </Button>
          }
          placement="bottomStart"
          items={actions(rowData)}
        />
      ),
    });
  }

  return (
    <Table
      columns={columns}
      data={manufacturers}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
    />
  );
};

/**
 * Custom validation function to check that the url starts with `https://`.
 */
const validateHttpsUrl = (value: string) => {
  const httpsPattern = /^https:\/\/.+$/;

  if (!httpsPattern.test(value)) {
    return t`components.admin-view.url-must-be-https`;
  }

  if (!URL.canParse(value)) {
    return t`components.admin-view.url-invalid`;
  }

  return true;
};

/**
 * Form for creating or editing a manufacturer record.
 *
 * If the `manufacturer` prop is provided, the form can be used for updating
 * that manufacturer record, otherwise a new manufacturer will be created.
 */
const ManufacturerEditForm = ({
  manufacturer,
  onClose,
}: {
  manufacturer?: Manufacturer;
  onClose: () => void;
}) => {
  const createManufacturer = useCreateManufacturer();
  const updateManufacturer = useUpdateManufacturer();
  const [_, addToast] = useToasts();

  const defaultValues = manufacturer || { name: "", website: "" };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    const { name, website } = data;

    if (manufacturer) {
      const update: ManufacturerUpdate = {
        name,
        website,
      };

      updateManufacturer.mutateAsync({
        id: manufacturer?.id,
        update,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.manufacturer-update-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    } else if (name && website) {
      const newManufacturer: NewManufacturer = {
        name,
        website,
      };

      createManufacturer.mutateAsync({
        newManufacturer,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.manufacturer-creation-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    }

    onClose?.();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        rules={{ required: t`components.admin-view.name-required` }}
        placeholder={t`components.admin-view.manufacturer-name`}
      />
      <FormTextField
        control={control}
        name="website"
        label={t`common.website`}
        rules={{
          validate: validateHttpsUrl,
          required: t`components.admin-view.name-required`,
        }}
        placeholder={t`common.website`}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

// If the `material` prop is provided, use the form for updating that material record.
// Otherwise, create a new material.
const MaterialEditForm = ({
  material,
  onClose,
}: {
  material?: Material;
  onClose: () => void;
}) => {
  const colorPickerRef = useRef<HTMLInputElement>(null);
  const createMaterial = useCreateMaterial();
  const updateMaterial = useUpdateMaterial();
  const [_, addToast] = useToasts();
  const { data: manufacturers } = useManufacturers();

  const defaultValues: MaterialValue = material
    ? {
        ...material,
        pigmented: material.pigmented,
        manufacturerId: material.manufacturer.id,
        capabilities: material.capabilities,
      }
    : {
        name: "",
        color: "#000000",
        pigmented: false,
        manufacturerId: undefined,
        capabilities: undefined,
      };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    const { name, color, pigmented, capabilities, manufacturerId } = data;

    if (material) {
      const update: Omit<MaterialUpdate, "archived"> = {
        name,
        pigmented,
        color: color.toUpperCase(),
        capabilities: capabilities,
        manufacturerId,
      };

      updateMaterial.mutateAsync({
        id: material?.id,
        update,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.material-update-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    } else if (name && color && pigmented && manufacturerId && capabilities) {
      const newMaterial: NewMaterial = {
        name,
        pigmented,
        color: color.toUpperCase(),
        manufacturerId,
        capabilities,
        domainId: ROOT_DOMAIN,
      };

      createMaterial.mutateAsync({
        newMaterial,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.material-creation-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    }
    onClose();
  });

  const pigmentOptions: Option<boolean>[] = [
    {
      label: t`components.admin-view.natural`,
      value: "natural",
      data: false,
    },
    {
      label: t`components.admin-view.pigmented`,
      value: "pigmented",
      data: true,
    },
  ];

  const manufacturerOptions: Option<Uuid>[] = useMemo(
    () => manufacturers?.map((mf) => ({ label: mf.name, value: mf.id })) ?? [],
    [manufacturers]
  );

  const simulationOptions: Option<Material["capabilities"]>[] = [
    {
      label: t`common.not-simulatable`,
      value: "notSimulatable",
      data: { simulation: false, optimization: false },
    },
    {
      label: t`common.simulatable`,
      value: "simulatable",
      data: { simulation: true, optimization: false },
    },
    {
      label: t`common.optimizable`,
      value: "optimizable",
      data: { simulation: true, optimization: true },
    },
  ];

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        rules={{ required: t`components.admin-view.name-required` }}
        placeholder={t`components.admin-view.material-name`}
      />
      <Controller
        control={control}
        name="color"
        render={({ field, fieldState }) => (
          <FormField
            label={t`common.color`}
            hint={fieldState.error?.message}
            invalid={fieldState.invalid}
          >
            <S.MaterialColorFormFieldContainer
              onClick={() => colorPickerRef.current?.click()}
            >
              <S.MaterialColorContainer>
                <S.MaterialColorBox $color={field.value} />
                {field.value}
                <input {...field} type="color" ref={colorPickerRef} />
              </S.MaterialColorContainer>
            </S.MaterialColorFormFieldContainer>
          </FormField>
        )}
      />
      <FormSelect
        control={control}
        name="pigmented"
        label={t`common.pigmented`}
        options={pigmentOptions}
        isSearchable={false}
      />
      <FormSelect
        control={control}
        name="manufacturerId"
        label={t`common.manufacturer`}
        options={manufacturerOptions}
        rules={{ required: t`components.admin-view.manufacturer-required` }}
        isSearchable={false}
      />
      <Controller
        control={control}
        name="capabilities"
        rules={{ required: t`components.admin-view.capabilities-required` }}
        render={({ field, fieldState }) => (
          <FormField
            label={t`common.simulation`}
            hint={fieldState.error?.message}
            invalid={fieldState.invalid}
          >
            <RadioGroup {...field}>
              {simulationOptions.map((opt, i) => (
                <RadioButton {...opt} key={i} />
              ))}
            </RadioGroup>
          </FormField>
        )}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

// Calculates a numerical value for a material's simulation capabilities to enable sorting.
const getRowCapabilityNumberValue = (row: Row<Material>) => {
  const { simulation, optimization } = row.original.capabilities;
  if (optimization) return 2;
  if (simulation) return 1;
  return 0;
};

// Column definitions shared between the Materials and Archived Materials tables.
// Formatted as a function that returns the array of `ColumnDef`s so that the `lingui`
// macro will continue to work as expected.
const sharedMaterialTableColumns: () => ColumnDef<Material>[] = () => [
  {
    id: "name",
    accessorKey: "name",
    header: t`common.name`,
    cell: ({ row: { original: rowData } }) => rowData.name,
  },
  {
    id: "color",
    accessorKey: "color",
    header: t`common.color`,
    cell: ({ row: { original: rowData } }) => (
      <S.MaterialColorContainer>
        <S.MaterialColorBox $color={rowData.color} />
        {rowData.color.toUpperCase()}
      </S.MaterialColorContainer>
    ),
  },
  {
    id: "pigmented",
    accessorKey: "pigmented",
    header: t`common.pigmented`,
    cell: ({ cell }) => cell.getValue() && <S.Checkmark>✓</S.Checkmark>,
  },
  {
    id: "manufacturer",
    header: t`common.manufacturer`,
    accessorKey: "manufacturer.name",
    cell: ({ row: { original: rowData } }) => (
      <a href={`#${rowData.manufacturer.id}`}>{rowData.manufacturer.name}</a>
    ),
  },
  {
    id: "simulatable",
    header: t`common.simulatable`,
    accessorKey: "capabilities",
    sortingFn: (rowA, rowB) => {
      return (
        getRowCapabilityNumberValue(rowB) - getRowCapabilityNumberValue(rowA)
      );
    },
    cell: ({ row: { original: rowData } }) => {
      const { simulation, optimization } = rowData.capabilities;

      if (!simulation) {
        return null;
      }

      return <S.Checkmark>{`✓${optimization ? " + Opt" : ""}`}</S.Checkmark>;
    },
  },
  {
    id: "id",
    header: t`common.id`,
    accessorKey: "id",
    cell: ({ row: { original: rowData } }) => <UuidTooltip uuid={rowData.id} />,
  },
];

const ArchivedMaterials = ({
  actions,
}: {
  actions: (material: Material) => MenuItem[];
}) => {
  const { data: materials } = useMaterials();

  const archivedMaterials = materials?.filter((material) => material.archived);

  if (!archivedMaterials || !archivedMaterials?.length) return null;

  const columns: ColumnDef<Material>[] = [
    ...sharedMaterialTableColumns(),
    {
      id: "archivedAt",
      header: t`common.archived-at`,
      accessorKey: "updatedAt",
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.updatedAt} />
      ),
    },
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <OverlayMenu
          target={
            <Button kind="secondary" size="small" icon>
              <Icon variant="MoreVert" />
            </Button>
          }
          placement="bottomStart"
          items={actions(rowData)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={archivedMaterials}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
    />
  );
};

const ArchiveMaterialForm = ({
  material,
  onClose,
}: {
  material: Material;
  onClose: () => void;
}) => {
  const updateMaterial = useUpdateMaterial();
  const [_, addToast] = useToasts();

  function onSubmit() {
    updateMaterial.mutateAsync({
      id: material.id,
      update: { archived: true },
      onError: (code?: number) => {
        const title =
          code === 403
            ? t`components.admin-view.action-permission-denied`
            : t`components.admin-view.material-archiving-failed`;

        addToast({
          title,
          kind: "warning",
          timeout: 5_000,
        });
      },
    });

    onClose?.();
  }

  return (
    <>
      <S.ArchiveMessage>
        {t`components.admin-view.confirm-archive-material`}
      </S.ArchiveMessage>
      <SubmitCancelButtons
        onCancel={onClose}
        onSubmit={onSubmit}
        submitButtonLabel={t`common.archive`}
        disableSubmit={false}
      />
    </>
  );
};

export const Materials = (): ReactNode => {
  const materialsModal = useModal<Material>();
  const archiveMaterialsModal = useModal<Material>();
  const manufacturersModal = useModal<Manufacturer>();

  const { data: materials } = useMaterials();
  const { data: rootPermissions } = useCurrentUserPermissions(ROOT_DOMAIN);
  const updateMaterial = useUpdateMaterial();
  const [_, addToast] = useToasts();

  const availableMaterials =
    materials?.filter((material) => !material.archived) ?? [];

  if (!availableMaterials?.length) return null;

  const canEditMaterials = testPermissions("materials/write", rootPermissions);

  const canEditManufacturers = testPermissions(
    "manufacturers/write",
    rootPermissions
  );

  const materialActions = (material: Material) =>
    canEditMaterials
      ? [
          {
            render: t`common.edit`,
            onClick: () => materialsModal.openModal(material),
          },
          {
            render: t`common.archive`,
            onClick: () => archiveMaterialsModal.openModal(material),
          },
        ]
      : [];

  const archivedMaterialActions = (material: Material) =>
    canEditMaterials
      ? [
          {
            render: t`common.unarchive`,
            onClick: () => {
              updateMaterial.mutateAsync({
                id: material.id,
                update: { archived: false },
                onError: (code?: number) => {
                  const title =
                    code === 403
                      ? t`components.admin-view.action-permission-denied`
                      : t`components.admin-view.material-unarchiving-failed`;

                  addToast({
                    title,
                    kind: "warning",
                    timeout: 5_000,
                  });
                },
              });
            },
          },
        ]
      : [];

  const manufacturerActions = (manufacturer: Manufacturer) =>
    canEditManufacturers
      ? [
          {
            render: t`common.edit`,
            onClick: () => manufacturersModal.openModal(manufacturer),
          },
        ]
      : [];

  const columns: ColumnDef<Material>[] = [
    ...sharedMaterialTableColumns(),
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <OverlayMenu
          target={
            <Button kind="secondary" size="small" icon>
              <Icon variant="MoreVert" />
            </Button>
          }
          placement="bottomStart"
          items={materialActions(rowData)}
        />
      ),
    },
  ];

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2></h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"materials/create"}
            render={({ allowed }) => (
              <Button
                onClick={() =>
                  materialsModal.openModal(materialsModal.modalData)
                }
                disabled={!allowed}
              >{t`common.create`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <Table
          columns={columns}
          data={availableMaterials}
          initialState={{ sorting: [{ id: "name", desc: false }] }}
        />
      </S.AdminContentSection>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`common.manufacturers`}</h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"manufacturers/create"}
            render={({ allowed }) => (
              <Button
                onClick={() =>
                  manufacturersModal.openModal(manufacturersModal.modalData)
                }
                disabled={!allowed}
              >{t`common.create`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <ManufacturersTable actions={manufacturerActions} />
      </S.AdminContentSection>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`components.admin-view.archived-materials`}</h2>
        </S.AdminContentSectionTitleContainer>
        <ArchivedMaterials actions={archivedMaterialActions} />
      </S.AdminContentSection>
      <archiveMaterialsModal.Modal
        title={t`components.admin-view.archive-material`}
      >
        {archiveMaterialsModal.isModalOpen &&
          archiveMaterialsModal.modalData && (
            <ArchiveMaterialForm
              material={archiveMaterialsModal.modalData}
              onClose={archiveMaterialsModal.closeModal}
            />
          )}
      </archiveMaterialsModal.Modal>
      <materialsModal.Modal
        title={
          materialsModal.modalData
            ? t`components.admin-view.edit-material`
            : t`components.admin-view.create-material`
        }
      >
        {materialsModal.isModalOpen && (
          <MaterialEditForm
            material={materialsModal.modalData}
            onClose={materialsModal.closeModal}
          />
        )}
      </materialsModal.Modal>
      <manufacturersModal.Modal
        title={
          manufacturersModal.modalData
            ? t`components.admin-view.edit-manufacturer`
            : t`components.admin-view.create-manufacturer`
        }
      >
        {manufacturersModal.isModalOpen && (
          <ManufacturerEditForm
            manufacturer={manufacturersModal.modalData}
            onClose={manufacturersModal.closeModal}
          />
        )}
      </manufacturersModal.Modal>
    </S.AdminContent>
  );
};

import styled from "styled-components";

export const Radio = styled.input`
  // Hide the default radio button styling.
  appearance: none;
  background-color: transparent;
  margin: 0;

  border: 1px solid ${(p) => p.theme.text.default};
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 100%;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${(p) => p.theme.color.brand.blue};
    transform: scale(0);
    transition: all 0.05s ease;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:focus {
    outline: none;
    border: 2px solid ${(p) => p.theme.color.brand.blue};
  }

  &:disabled {
    border: 1px solid ${(p) => p.theme.text.disabled};
    cursor: not-allowed;
  }
`;

export const Label = styled.label<{ $polite: boolean }>`
  & > span {
    margin: ${(p) => (p.$polite ? "0 0 0 8px" : "0 8px 0 0")};
    cursor: pointer;
  }

  & input:disabled + span {
    color: ${(p) => p.theme.text.disabled};
    cursor: not-allowed;
  }
`;

export const GroupWrapper = styled.div<{ $row: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.$row ? "row" : "column")};
  align-items: flex-start;

  & > * {
    margin-bottom: ${(p) => (p.$row ? "0" : "10px")};
    margin-right: ${(p) => (p.$row ? "10px" : "0")};
    display: inline-flex;
    align-items: baseline;
  }

  & > *:last-of-type {
    margin: 0;
  }
`;
